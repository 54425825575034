<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addProduct'})">{{$t('products.products')}}</dashboard-page-title>
    <main-table :fields="fields" :list_url="'merchant/products'" paginationName="منتجات"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
// import productServices from '@/modules/products/services/products'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      fields: [
        { label: this.$t('products.mainImage'), key: 'featured_image', class: 'text-right', type: 'image' },
        { label: this.$t('products.name'), key: 'name', class: 'text-right' },
        { label: this.$t('products.images'), key: 'product_images', class: 'text-right', type: 'multi_image' },
        { label: this.$t('products.price'), key: 'price', class: 'text-right' },
        { label: this.$t('products.quantity'), key: 'quantity', class: 'text-right' },
        { label: this.$t('products.date'), key: 'created_at', class: 'text-right' },
        { label: this.$t('products.date'), key: 'created_at', class: 'text-right' },
        { label: this.$t('products.status'), key: 'status', class: 'text-right', type: 'status' },
        {
          label: this.$t('products.control'),
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'view',
              icon: 'ri-eye-fill',
              color: 'primary',
              ifNavigate: true,
              routePath: 'show-product/:id'
            },
            {
              text: 'edit',
              icon: 'ri-ball-pen-fill',
              color: 'info',
              ifNavigate: true,
              routePath: 'edit-product/:id'
            },
            {
              text: 'Delete',
              icon: 'ri-delete-bin-line',
              color: 'danger',
              url: 'merchant/products',
              titleHeader: 'منتج',
              question: 'هل متأكد أنك تريد مسح هذا المنتج',
              textContent: 'name',
              textDeleteButton: 'بالتأكيد, مسح',
              textCancelButton: 'الغاء',
              showAlert: true
            }
          ]
        }
      ]
    }
  },
  methods: {
    addMerchant () {
    }
    // deleteProduct (id) {
    //   productServices.deleteProduct(id).then(res => {
    // this.products.splice(i, 1)
    // this.product.splice(this.events.indexOf(product), 1);
    //   })
    // }
  }
}
</script>
